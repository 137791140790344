import { ArticleCardType, CaseStudyArticleType } from 'components/types';
import { omit, pick } from 'lodash';

export const convertCaseStudyArticlesToArticleCardFormat = (
  list: CaseStudyArticleType[],
): ArticleCardType[] => {
  return list.map(article => ({
    ...omit(article, 'hfs_case_study_topic'),
    category: {
      name: '',
      ...pick(
        article.hfs_case_study_topic,
        'name',
        'slug',
        'backgroundHexCodeColor',
        'textHexColorCode',
      ),
    },
  }));
};
