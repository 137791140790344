import React from 'react';
import SocialInfo from './SocialInfo';
import Map from 'components/Map';
import LocationCards from 'components/locationCards';
import { ConnectCardType } from './type';
import { graphql, useStaticQuery } from 'gatsby';

interface ConnectProps extends ConnectCardType {}

const Connect = ({ haveBtn, location, handleClickOnBtn }: ConnectProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSocialMediaPrompt {
        nodes {
          MapImg {
            url
          }
          MapImgLink
        }
      }
    }
  `);
  const { MapImg, MapImgLink } = data.allStrapiSocialMediaPrompt.nodes[0];
  return (
    <div className="bg-accent_grey_1">
      {location === 'map' ? (
        <div className="flex flex-col lg:flex-row gap-10 py-[60px] justify-center px-[30px] lg:px-0 items-center">
          <SocialInfo
            haveBtn={haveBtn}
            location={location}
            handleClickOnBtn={handleClickOnBtn}
          />
          <Map
            mapImg={MapImg.url}
            mapUrl={MapImgLink}
            imgClassName="h-[300px] w-full lg:w-[392px]"
          />
        </div>
      ) : (
        <div className="flex flex-col gap-10 py-[60px] justify-center px-[30px] lg:px-0 items-center">
          <SocialInfo
            haveBtn={haveBtn}
            location={location}
            handleClickOnBtn={handleClickOnBtn}
          />
          <LocationCards isConnectCard={false} />
        </div>
      )}
    </div>
  );
};

export default Connect;
