import { IMG_LINK_LIST } from 'components/constant';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw, { styled } from 'twin.macro';

const StrapiParagraph = styled.p`
  margin-bottom: 0;
  p {
    margin-bottom: 0;
  }
`;
const ComponentContainer = tw.div`
py-[27px] px-6 lg:px-[59px] lg:pt-[39px] lg:pb-[40px] rounded-[5px] text-accent_blue_1 text-[14px] lg:text-[16px]
`;
const H2Header = tw.h2`
!font-[500] text-[24px] leading-[36px] mb-[20px] lg:text-[36px] lg:leading-[54px] lg:mb-[14px]
`;

const Box = tw.div`
bg-white leading-[151%] p-9 flex flex-col gap-6 rounded-[5px] max-w-[696px]
`;

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiHfsLandingPage {
        nodes {
          aboutDivision {
            data {
              aboutDivision
            }
          }
          aboutHeader
          aboutSubHeader
          aboutMission {
            data {
              aboutMission
            }
          }
          aboutBackgroundImage {
            url
          }
        }
      }
    }
  `);
  const {
    aboutDivision: {
      data: { aboutDivision },
    },
    aboutHeader,
    aboutSubHeader,
    aboutMission: {
      data: { aboutMission },
    },
    aboutBackgroundImage: { url: backgroundUrl },
  } = data.allStrapiHfsLandingPage.nodes[0];

  return (
    <ComponentContainer
      style={{
        background: `url(${backgroundUrl}), lightgray 324.333px -1.725px / 100% 100% no-repeat`,
      }}
    >
      <p className="font-[500] mb-0">{aboutSubHeader}</p>
      <H2Header>{aboutHeader}</H2Header>

      <Box>
        <div className="flex flex-col gap-6 lg:flex-row">
          <img
            src={IMG_LINK_LIST.hfs.blackLogo}
            alt="logo"
            className="object-contain h-[50px] self-start lg:self-center"
          />
          <StrapiParagraph
            dangerouslySetInnerHTML={{ __html: aboutDivision }}
          />
        </div>

        <StrapiParagraph dangerouslySetInnerHTML={{ __html: aboutMission }} />
      </Box>
    </ComponentContainer>
  );
};

export default About;
