import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import LocationCard from './LocationCard';
import { LocationCardType } from './type';

interface LocationCardListProps {
  isConnectCard: boolean;
}
const LocationCardList = ({ isConnectCard }: LocationCardListProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          OfficeLocationCard {
            Country
            Address
            PhoneNumber
            GoogleMapLink
          }
        }
      }
    }
  `);
  const { OfficeLocationCard } = data.allStrapiLandingPage.nodes[0];
  const locationCardList: LocationCardType[] = OfficeLocationCard.map(
    ({
      Country,
      Address,
      PhoneNumber,
      GoogleMapLink,
    }: Record<string, string>) => ({
      nation: Country,
      address: Address,
      phone: PhoneNumber,
      map: GoogleMapLink,
    }),
  );
  const connectCardCss = isConnectCard ? 'lg:grid-cols-1' : 'lg:grid-cols-3';
  return (
    <div
      className={`grid grid-cols-1 justify-center items-center gap-6 w-fit mx-auto ${connectCardCss}`}
    >
      {locationCardList.map(card => (
        <LocationCard
          key={card.nation}
          {...card}
          isConnectCard={isConnectCard}
        />
      ))}
    </div>
  );
};

export default LocationCardList;
