import React from 'react';
import tw, { styled } from 'twin.macro';
import { BenefitCardType } from './type';
import Link from 'components/Link';

interface BenefitCardProps extends BenefitCardType {}

const CardWrapper = tw.div`
max-w-[408px] lg:max-w-full lg:w-full grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-[80px] px-[30px] lg:px-0 lg:mb-[48px] lg:items-center
`;
const ContentWrapper = tw.div`
mt-4 mb-12 lg:my-0 max-w-[480px] space-y-4
`;
const Title = tw.h2`
text-[20px] lg:text-[24px] !font-[500] text-base_text mb-4 
`;
const StyledLink = tw(Link)`
py-[6px] flex gap-2 items-center justify-center w-fit text-accent_blue_2 hover:opacity-50 font-[500]
`;
const Description = styled.div`
  ${tw`text-[14px] text-[16px] leading-[175%]`}
  strong {
    font-weight: 500;
  }
`;
const BenefitCard = ({
  title,
  description,
  buttonLink,
  buttonLabel,
  youtubeEmbedCode,
  youtubeTitle,
}: BenefitCardProps) => {
  return (
    <CardWrapper>
      <iframe
        width="100%"
        height="372"
        src={`https://www.youtube.com/embed/${youtubeEmbedCode}`}
        title={youtubeTitle}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
      />
      <ContentWrapper>
        <Title>{title}</Title>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <StyledLink to={buttonLink}>
          <span>{buttonLabel}</span>
          <span className=" fa-regular fa-arrow-right" />
        </StyledLink>
      </ContentWrapper>
    </CardWrapper>
  );
};

export default BenefitCard;
