import React from 'react';
import BenefitCard from './BenefitCard';

import { graphql, useStaticQuery } from 'gatsby';
import tw, { styled } from 'twin.macro';
import useHFS from 'components/HFS/hooks/useHFS';
import { BenefitCardType } from './type';
import { HFSModals } from 'components/constant';

const StrapiRichText = styled.div`
  ${tw`text-[16px] lg:text-[20px] leading-[175%]`}
  p {
    margin-bottom: 0px;
  }
  strong {
    font-weight: 500;
  }
`;
const ComponentWrapper = tw.div`
mt-[60px] mb-[12px] lg:pb-[12px] text-base_text
`;
const MessageWrapper = tw.div`
px-[30px] lg:px-0 text-center
`;
const MobileHeader = tw.h2`
md:hidden !font-[500] text-[24px] mb-2
`;
const WebHeader = tw.h2`
hidden md:block mb-4
`;
const ListWrapper = tw.div`
mt-[24px] md:mt-[100px] flex flex-col lg:gap-4 justify-center items-center lg:items-start
`;
const OutlinedButton = tw.button`
border border-accent_blue_1 px-3 py-[6px] rounded-[5px] hover:opacity-50 text-accent_blue_1 mt-6
`;
const HSFBenefits = () => {
  const { setActiveModal } = useHFS();
  const data = useStaticQuery(graphql`
    query {
      allStrapiHfsLandingPage {
        nodes {
          goToMarketMessageDescription
          goToMarketButtonLabel
          goToMarketMessageHeader
          goToMarketProducts {
            strapi_id
            buttonLink
            buttonLabel
            description
            title
            youtubeEmbedCode
            youtubeTitle
          }
        }
      }
    }
  `);
  const {
    goToMarketMessageDescription,
    goToMarketButtonLabel,
    goToMarketMessageHeader,
    goToMarketProducts,
  } = data.allStrapiHfsLandingPage.nodes[0];

  return (
    <ComponentWrapper>
      <MessageWrapper>
        <WebHeader>{goToMarketMessageHeader}</WebHeader>
        <MobileHeader>{goToMarketMessageHeader}</MobileHeader>
        <StrapiRichText
          dangerouslySetInnerHTML={{ __html: goToMarketMessageDescription }}
        />
        <OutlinedButton
          onClick={() => {
            setActiveModal(HFSModals.DOWNLOAD_BROCHURE);
          }}
        >
          {goToMarketButtonLabel}
        </OutlinedButton>
      </MessageWrapper>

      <ListWrapper>
        {goToMarketProducts.map((card: BenefitCardType) => {
          const { strapi_id: id } = card;
          return <BenefitCard key={id} {...card} />;
        })}
      </ListWrapper>
    </ComponentWrapper>
  );
};

export default HSFBenefits;
