import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

const ComponentWrapper = tw.div`
 z-1 text-white px-[30px] py-6 md:py-[27px] lg:px-0 h-fit
`;

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiHfsLandingPage {
        nodes {
          heroFooterBannerCTAHeader
        }
      }
    }
  `);
  const { heroFooterBannerCTAHeader } = data.allStrapiHfsLandingPage.nodes[0];
  return (
    <ComponentWrapper>
      <p className="text-[16px] lg:text-[20px] mb-0">
        {heroFooterBannerCTAHeader}
      </p>
    </ComponentWrapper>
  );
};

export default Banner;
