import React from 'react';

interface MapProps {
  imgClassName?: string;
  mapImg: string;
  mapUrl: string;
}
const Map = ({ imgClassName = '', mapImg, mapUrl }: MapProps) => {
  return (
    <a
      data-testid="map-component"
      href={mapUrl}
      target="_blank"
      className={`inline-block ${imgClassName}`}
      style={{ background: `url(${mapImg}) no-repeat center center/cover` }}
    />
  );
};

export default Map;
