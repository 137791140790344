import * as React from 'react';

import { PREFIX_CANONICAL_URL } from 'components/constant';

import HFSLayout from 'components/HFS/HFSLayout';
import { graphql } from 'gatsby';
import SEOGraphQL from 'components/SEOGraphQL';
import HFSLandingPage from 'components/pages/HFS/landingPage';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';
import { withIntl } from 'LanguageProvider/withLanguageProvider';
import translation from 'intl';

const pageUrl = `${PREFIX_CANONICAL_URL}/financial`;

const HFSPage = () => {
  return (
    <HFSLayout headerStyle="transparent">
      <BreadcrumbList pageUrl={pageUrl} pageLabelId="structuredData.hfs" />
      <HFSLandingPage />
    </HFSLayout>
  );
};
export default withIntl(translation)(HFSPage);

export const Head = ({
  data,
}: {
  data: { allStrapiHfsLandingPage: { nodes: { seo: any }[] } };
}) => {
  const SEO = data.allStrapiHfsLandingPage.nodes[0].seo;
  const seo = data.allStrapiHfsLandingPage.nodes[0].seo.preventIndexing;
  return <SEOGraphQL data={{ SEO, seo }} canonicalURL={pageUrl} />;
};

export const query = graphql`
  query {
    allStrapiHfsLandingPage {
      nodes {
        seo {
          id
          metaDescription
          metaSocial {
            id
            socialNetwork
            image {
              url
            }
            description
          }
          metaTitle
          preventIndexing
        }
      }
    }
  }
`;
