import React from 'react';
import { ConnectCardType, SocialItemType } from './type';
import Link from 'components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import tw from 'twin.macro';

interface SocialItemProps extends SocialItemType {}
interface SocialInfoProps extends ConnectCardType {}

const StyledButton = tw.button`
rounded-[5px] text-hubble_blue border border-hubble_blue bg-white px-3 py-[6px] hover:bg-hubble_blue hover:text-white mt-6 inline-block
`;

const SocialItem = ({ label, icon, href }: SocialItemProps) => {
  return (
    <a
      href={href}
      target="_blank"
      className="flex items-center justify-start gap-3 text-base_text hover:opacity-50 hover:text-base_text flex-wrap	"
    >
      <span className={`text-[20px] text-base_text ${icon}`} />
      <p className="text-[14px] md:text-[16px] m-0">{label}</p>
    </a>
  );
};

const SocialInfo = ({
  haveBtn,
  location,
  handleClickOnBtn,
}: SocialInfoProps) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSocialMediaPrompt {
        nodes {
          CTAButtonLabel
          CTAButtonLink
          Header
          SubHeader
          SocialNetworkingPlatform {
            Link
            Label
            FontAwesomeIcon
          }
        }
      }
    }
  `);
  const {
    CTAButtonLabel,
    CTAButtonLink,
    Header,
    SubHeader,
    SocialNetworkingPlatform,
  } = data.allStrapiSocialMediaPrompt.nodes[0];
  const socialList: SocialItemType[] = SocialNetworkingPlatform.map(
    ({ Link, Label, FontAwesomeIcon }: Record<string, any>) => ({
      label: Label,
      href: Link,
      icon: FontAwesomeIcon,
    }),
  );
  const socialItemWrapperCss =
    location === 'map'
      ? 'grid grid-cols-1 lg:grid-cols-2 gap-3 pl-5'
      : 'flex flex-wrap gap-x-6 gap-y-4 pl-5 ';
  return (
    <div>
      <h3 className="text-hubble_blue mb-6">{Header}</h3>

      <div className="connect-card-accent">
        <p className="text-[14px] lg:text-[16px] font-[500] text-hubble_blue mb-6 font-[700]">
          {SubHeader}
        </p>
        <div className={socialItemWrapperCss}>
          {socialList.map(item => (
            <SocialItem key={item.label} {...item} />
          ))}
        </div>
      </div>

      {haveBtn === 'href' && (
        <Link
          to={CTAButtonLink}
          className="text-hubble_blue border border-hubble_blue bg-white px-3 py-2 hover:bg-hubble_blue hover:text-white mt-6 inline-block "
        >
          {CTAButtonLabel}
        </Link>
      )}
      {haveBtn === 'onClick' && (
        <StyledButton
          onClick={() => {
            handleClickOnBtn?.();
          }}
        >
          {CTAButtonLabel}
        </StyledButton>
      )}
    </div>
  );
};

export default SocialInfo;
