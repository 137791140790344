import { STRUCTURED_DATA_CONTEXT } from 'components/constant';
import React from 'react';
import { useIntl } from 'react-intl';
import StructuredDataScript from './StructuredDataScript';
import {
  createBreadcrumbListHFSStructuredData,
  createBreadcrumbListStructuredData,
} from './structuredDataUtils';
import { BreadcrumbForPageType } from './types';

interface BreadcrumbListProps {
  pageUrl: string;
  pageLabelId: string;
  isHFSPage?: boolean;
}

const BreadcrumbList = ({
  pageUrl,
  pageLabelId,
  isHFSPage = false,
}: BreadcrumbListProps) => {
  const { formatMessage } = useIntl();
  const pageName = formatMessage({ id: pageLabelId });
  const breadcrumb = isHFSPage
    ? createBreadcrumbListHFSStructuredData(pageUrl, pageName, formatMessage)
    : createBreadcrumbListStructuredData(pageUrl, pageName, formatMessage);
  const structuredData: BreadcrumbForPageType = {
    '@context': STRUCTURED_DATA_CONTEXT,
    ...breadcrumb,
  };
  return <StructuredDataScript data={structuredData} />;
};

export default BreadcrumbList;
