import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface CustomerCarouselProps {
  children: React.ReactNode;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 400,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: -10,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: -10,
  },
};
const CustomLeftArrow = ({ ...rest }) => {
  const { onClick } = rest;
  return (
    <button
      className="text-accent_blue_2 absolute bottom-0 left-[5px]"
      onClick={() => onClick()}
    >
      <span className="fa-regular fa-chevron-left text-[20px]"></span>
    </button>
  );
};
const CustomRightArrow = ({ ...rest }) => {
  const { onClick } = rest;
  return (
    <button
      className="text-accent_blue_2 absolute bottom-0 left-[40px]"
      onClick={() => onClick()}
    >
      <span className="fa-regular fa-chevron-right text-[20px]"></span>
    </button>
  );
};

const CustomerCarousel = ({ children }: CustomerCarouselProps) => {
  return (
    <div className="relative max-h-[720px] md:h-[430px]">
      <Carousel
        ssr
        partialVisible
        responsive={responsive}
        infinite={true}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CustomerCarousel;
