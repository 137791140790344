import ArticleCard from 'components/articleList/ArticleCardV2';
import CustomerCarousel from 'components/testimonial/components/CustomerCarousel';
import { ArticleCardType } from 'components/types';
import React, { ReactNode } from 'react';

interface ExploreRelatedArticleProps {
  exploreTitle: string;
  exploreDescription: string;
  id: string;
  articleList: ArticleCardType[];
  hasBackground?: boolean;
}
interface WebCardListContainerProps {
  children: ReactNode;
}

const WebCardListContainer = ({ children }: WebCardListContainerProps) => {
  return <div className="hidden lg:grid grid-cols-3 gap-6">{children}</div>;
};
interface MobileCardListContainerProps {
  children: ReactNode;
}

const MobileCardListContainer = ({
  children,
}: MobileCardListContainerProps) => {
  return <div className="lg:hidden">{children}</div>;
};

const ExploreRelatedArticle = ({
  exploreTitle,
  exploreDescription,
  id = 'newsroom',
  articleList,
  hasBackground = false,
}: ExploreRelatedArticleProps) => {
  const currentArticles = articleList?.slice(0, 3);
  return (
    <>
      {articleList?.length > 0 && (
        <div
          className={
            hasBackground ? 'explore-background mt-[40px] lg:mt-[60px]' : ''
          }
        >
          <div className="text-base_text py-[60px] px-[30px] lg:px-0">
            <h3 className="mb-2">{exploreTitle}</h3>
            <p className="text-[14px] lg:text-[16px] mb-9">
              {exploreDescription}
            </p>

            <WebCardListContainer>
              {currentArticles.map((card, index) => {
                return <ArticleCard {...card} key={index} id={id} />;
              })}
            </WebCardListContainer>

            <MobileCardListContainer>
              <CustomerCarousel>
                {currentArticles.map((card, index) => (
                  <div className="min-h-[425px] max-w-[400px]" key={index}>
                    <div className="bg-white shadow-[0px_1px_5px_rgba(41,45,55,0.15)]">
                      <ArticleCard {...card} id={id} />
                    </div>
                  </div>
                ))}
              </CustomerCarousel>
            </MobileCardListContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default ExploreRelatedArticle;
