import React from 'react';
import SEO from './SEO';
import { PageSEOType } from './types';

interface SEOGraphQLProps {
  data: PageSEOType;
  canonicalURL: string;
}

const SEOGraphQL = ({ data, canonicalURL }: SEOGraphQLProps) => {
  if (!data) return null;
  const { metaTitle, metaDescription, metaSocial } = data.SEO;
  return (
    <SEO
      pageTitle={metaTitle}
      description={metaDescription}
      ogTitle={metaSocial?.title}
      ogDescription={metaSocial?.description}
      ogImg={metaSocial?.image?.url}
      preventIndexing={data.seo.preventIndexing}
      canonicalURL={canonicalURL}
    />
  );
};

export default SEOGraphQL;
