import { withIntl } from 'LanguageProvider/withLanguageProvider';
import translation from './translations';
import React from 'react';
import LandingHero from './HFSLandingHero';
import Banner from './HFSBanner';
import LayoutContainer from 'components/LayoutContainer';
import HFSBenefits from './benefits';
import ConnectCard from 'components/connectCard';
import useHFS from 'components/HFS/hooks/useHFS';
import About from './About';
import { HFSModals } from 'components/constant';
import { graphql, useStaticQuery } from 'gatsby';
import ExploreRelatedArticle from 'components/exploreRelatedArticles';
import { useIntl } from 'react-intl';
import { ArticlePageId } from 'components/types';
import { convertCaseStudyArticlesToArticleCardFormat } from 'components/HFS/utils';

const HFSLandingPage = () => {
  const { setActiveModal } = useHFS();
  const { formatMessage } = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allStrapiHfsLandingPage {
        nodes {
          hfs_case_study_articles {
            title
            publishedAt
            cover {
              alternativeText
              url
              formats {
                small {
                  url
                }
              }
            }
            author {
              name
            }
            hfs_case_study_topic {
              name
              textHexColorCode
              backgroundHexCodeColor
            }
            description
            slug
            readingTime {
              text
            }
          }
        }
      }
    }
  `);
  const articleList = convertCaseStudyArticlesToArticleCardFormat(
    data.allStrapiHfsLandingPage.nodes[0].hfs_case_study_articles,
  );
  return (
    <>
      <LandingHero />

      <div className="bg-accent_blue_2">
        <LayoutContainer>
          <Banner />
        </LayoutContainer>
      </div>

      <LayoutContainer>
        <HFSBenefits />
      </LayoutContainer>

      <div className="bg-accent_grey_1 py-16 px-[30px] lg:px-0">
        <LayoutContainer>
          <About />
        </LayoutContainer>
      </div>

      <div className="connect-card-background">
        <ConnectCard
          haveBtn="onClick"
          location="map"
          handleClickOnBtn={() => {
            setActiveModal(HFSModals.CONTACT_US);
          }}
        />
      </div>
      <div className="bg-accent_grey_1 ">
        <LayoutContainer>
          <ExploreRelatedArticle
            exploreTitle={formatMessage({
              id: 'article.explore.title',
            })}
            exploreDescription={formatMessage({
              id: 'article.explore.description',
            })}
            id={ArticlePageId.CASE_STUDIES}
            articleList={articleList}
          />
        </LayoutContainer>
      </div>
    </>
  );
};

export default withIntl(translation)(HFSLandingPage);
