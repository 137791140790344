import React from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import './style.css';
import Connect from './Connect';
import { ConnectCardType } from './type';

interface ConnectCardProps extends ConnectCardType {}

const ConnectCard = (props: ConnectCardProps) => {
  return (
    <LanguageProvider messages={translation}>
      <Connect {...props} />
    </LanguageProvider>
  );
};

export default ConnectCard;
