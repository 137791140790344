import { LocationCardType } from './type';
import React from 'react';

interface LocationCardProps extends LocationCardType {
  isConnectCard: boolean;
}
interface FormattedTextProps {
  text: string;
  isConnectCard: boolean;
}

const FormattedText = ({ text, isConnectCard }: FormattedTextProps) => {
  const connectCardTextCss = isConnectCard ? '' : 'md:text-[16px] ';

  return <p className={`m-0 text-[14px] ${connectCardTextCss}`}>{text}</p>;
};

const LocationCard = ({
  nation,
  address,
  code,
  phone,
  map,
  isConnectCard,
}: LocationCardProps) => {
  const connectCardCss = isConnectCard
    ? 'grid md:grid-cols-[1fr_2fr] max-w-[550px] h-[160px] md:h-[110px] md:gap-4 justify-between items-center'
    : 'md:h-[200px] max-w-[408px]';
  const connectCardTextCss = isConnectCard ? '' : 'md:text-[24px]';
  return (
    <a
      href={map}
      target="_blank"
      className={`text-black hover:text-black px-6 py-4 bg-white shadow-[0px_1px_5px_rgba(41,45,55,0.15)] rounded-[5px] my-auto ${connectCardCss}`}
    >
      <p className={`text-[18px] font-[500] ${connectCardTextCss}`}>{nation}</p>
      <div>
        <FormattedText text={address} isConnectCard={isConnectCard} />
        {code && <FormattedText text={code} isConnectCard={isConnectCard} />}
        {phone && <FormattedText text={phone} isConnectCard={isConnectCard} />}
      </div>
    </a>
  );
};

export default LocationCard;
