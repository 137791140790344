import React from 'react';
import LanguageProvider from 'LanguageProvider/index';
import translation from './translation';
import LocationCardList from './LocationCardList';

interface LocationCardsProps {
  isConnectCard?: boolean;
}

const LocationCards = ({ isConnectCard = false }: LocationCardsProps) => {
  return (
    <LanguageProvider messages={translation}>
      <LocationCardList isConnectCard={isConnectCard} />
    </LanguageProvider>
  );
};

export default LocationCards;
